import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './components/App';
import DisableDevtool from 'disable-devtool';

DisableDevtool({
  disableMenu: false,
  disableCopy: true,
  disablePaste: true,
  disableCut: true
});

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);