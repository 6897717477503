const listeEpisodes = [
  {
    episode: 1,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/6_HLRqiwDRA",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/cnJXHbVnNNk",
      },
    ],
  },
  {
    episode: 2,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/6Yo0gcac8xU",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/U0kXEClNf98",
      },
    ],
  },
  {
    episode: 3,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/nUyhgp86E5Q",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/kGpva8q_7fo",
      },
    ],
  },
  {
    episode: 4,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/90OuVZFOSE8",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/jt-DpR7bFi8",
      },
    ],
  },
  {
    episode: 5,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/llp-SGB-9Fc",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/tMKZMqP8EOo",
      },
    ],
  },
  {
    episode: 6,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/L098-FSjL60",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/99GBN4wjq-M",
      },
    ],
  },
  {
    episode: 7,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/99_LGV11If4",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/V3KSiHbEj88",
      },
    ],
  },
  {
    episode: 8,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/K3scyLbY2HA",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/nrW5C-HoLaQ",
      },
    ],
  },
  {
    episode: 9,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/EYWUffyi22s",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/kXl4XYo-Vpo",
      },
    ],
  },
  {
    episode: 10,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/teu7UInQLqE",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/0MsEj4-n89k",
      },
    ],
  },
  {
    episode: 11,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/we2Wratblfs",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/AAd3OjQatA4",
      },
    ],
  },
  {
    episode: 12,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/wjPOnLzU2xM",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/gfVRoHRu_Xk",
      },
    ],
  },
  {
    episode: 13,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Rk-dNfSprps",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/sRTcZIBvnn4",
      },
    ],
  },
  {
    episode: 14,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/G9ujz41gARw",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/itZ7aEcdoAw",
      },
    ],
  },
  {
    episode: 15,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/OWB54mLidUM",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/5PR5ZAexHH4",
      },
    ],
  },
  {
    episode: 16,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/9icMgWgmvN4",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/NcAN4Dy4Vvs",
      },
    ],
  },
  {
    episode: 17,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/ZnTvASKHIAE",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/Iot79n54f5o",
      },
    ],
  },
  {
    episode: 18,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/3FcVsWrYzhg",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/1Go3aghnSMs",
      },
    ],
  },
  {
    episode: 19,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/nSMLRhb0Sdo",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/ILnHMq5rMpo",
      },
    ],
  },
  {
    episode: 20,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/JCgwrjnmrTs",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/q54d0C2ESB4",
      },
    ],
  },
  {
    episode: 21,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/h0TXeApmRnc",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/CjXYe8BWxjw",
      },
    ],
  },
  {
    episode: 22,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/i46qKDoNYR8",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/XDuA0DZaMsk",
      },
    ],
  },
  {
    episode: 23,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/_4r9NN5K-Lw",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/hXTvbap-fyc",
      },
    ],
  },
  {
    episode: 24,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/WRSifYh9JMc",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/NS_rWyi3uYA",
      },
    ],
  },
  {
    episode: 25,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/wwkGRS8CSgw",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/wS3EupNc9Uw",
      },
    ],
  },
  {
    episode: 26,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/j0dFQPEL3-Q",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/7J_Jq0KExGw",
      },
    ],
  },
  {
    episode: 27,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/IcR1MibHZb0",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/u6zsSpDqj5E",
      },
    ],
  },
  {
    episode: 28,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Z4m5sauE7LY",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/0VWgbOt2-QQ",
      },
    ],
  },
  {
    episode: 29,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/jAQTqmrT8Nw",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/ckCu2mAGShw",
      },
    ],
  },
  {
    episode: 30,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/ajvog3cLtQI",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/XDUpfTUYVQs",
      },
    ],
  },
  {
    episode: 31,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/FhIB5VbVK3E",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/I_PJgkabteo",
      },
    ],
  },
  {
    episode: 32,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/ft47Sez6a0A",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/A1R0hLSySsQ",
      },
    ],
  },
  {
    episode: 33,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/zsAKNjh_8VY",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/xkHeXnwZjJw",
      },
    ],
  },
  {
    episode: 34,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/KbobEl3_Cbc",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/botoEJxie2s",
      },
    ],
  },
  {
    episode: 35,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/bWda_1RoDjg",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/IxImQRyI8nE",
      },
    ],
  },
  {
    episode: 36,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/F96t_K3XwJU",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/i1xFJjRHjbU",
      },
    ],
  },
  {
    episode: 37,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/etWGrfy8jsY",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/oYB3jDwP_sI",
      },
    ],
  },
  {
    episode: 38,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Cjr0L7JjlKs",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/a2EpfLbcJj8",
      },
    ],
  },
  {
    episode: 39,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/YUcrmDGDNr4",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/buBjIM_rOuc",
      },
    ],
  },
  {
    episode: 40,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/E6DsU3sTvbI",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/IpeAmPG_ZiM",
      },
    ],
  },
  {
    episode: 41,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/EYKpqMtdh4k",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/Eoks7qs-8s0",
      },
    ],
  },
  {
    episode: 42,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/bT0Q3vFUm9c",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/pglDustFCjI",
      },
    ],
  },
  {
    episode: 43,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/K6bwHsjDFo8",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/4dYcyoEvO_Q",
      },
    ],
  },
  {
    episode: 44,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/7LVTYueWCzw",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/rxzrsFKu-P4",
      },
    ],
  },
  {
    episode: 45,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Xu_YiW_q_BY",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/Tdl-GQbvp-Q",
      },
    ],
  },
  {
    episode: 46,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/yVTltsEXs5s",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/rnGKcqRCdp0",
      },
    ],
  },
  {
    episode: 47,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/dM1PFkFxEuA",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/UpZeioesYGQ",
      },
    ],
  },
  {
    episode: 48,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/RZ_DV3YPbM4",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/9dY7QUCvbwk",
      },
    ],
  },
  {
    episode: 49,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/wASMIlO-aM4",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/dGlg847-Mmg",
      },
    ],
  },
  {
    episode: 50,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/XgmyqWb3b3I",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/-Z7xm9D9BjQ",
      },
    ],
  },
  {
    episode: 51,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Ohhc_PosL-g",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/xlV2UIAzb4c",
      },
    ],
  },
];

export default listeEpisodes;
