const listeEpisodes = [
  {
    episode: 1,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/7a0lElhOtUc",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/7hap8imirag",
      },
    ],
  },
  {
    episode: 2,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/O9V7OAWI3NQ",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/wEOQ1s6JySo",
      },
    ],
  },
  {
    episode: 3,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/orBeTasKtfo",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/6SJ2fWP_fV8",
      },
    ],
  },
  {
    episode: 4,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/-WU3cPsEBow",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/QuRR3J6VHmI",
      },
    ],
  },
  {
    episode: 5,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/zvZYLuKAsCw",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/7rGpWxTwbBk",
      },
    ],
  },
  {
    episode: 6,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/4tDMpfEVV1E",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/y69JiF1Uo0U",
      },
    ],
  },
  {
    episode: 7,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/URag5D79J1E",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/eimzQp7pfWI",
      },
    ],
  },
  {
    episode: 8,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/zS4hdYuX1RI",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/gFrj18eum6s",
      },
    ],
  },
  {
    episode: 9,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/R7FiFBcqhSM",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/ED8ffvkCifE",
      },
    ],
  },
  {
    episode: 10,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/59-ZXv3IFIk",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/taqyidwsSEg",
      },
    ],
  },
  {
    episode: 11,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/62Atk25uot0",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/iX4vUn7-n38",
      },
    ],
  },
  {
    episode: 12,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/CEnnuLh7UdU",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/8-VjrZCMrW0",
      },
    ],
  },
  {
    episode: 13,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/1asNfUc78Kg",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/G2ZWbuRKUsE",
      },
    ],
  },
  {
    episode: 14,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/hdB-lb7QWaU",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/pXjL5xU6YZ8",
      },
    ],
  },
  {
    episode: 15,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/GVGo4mL7AR4",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/4JuBOx7wXbs",
      },
    ],
  },
  {
    episode: 16,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/9m8aEdOj8ZY",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/-SQ54ucjmcU",
      },
    ],
  },
  {
    episode: 17,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Gko4Rm4lyq4",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/4FNHzctt0Mc",
      },
    ],
  },
  {
    episode: 18,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Uvjz-xo9K1s",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/MohtohiUgVs",
      },
    ],
  },
  {
    episode: 19,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/uw81S3ewcEs",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/0xNSsMFcD-8",
      },
    ],
  },
  {
    episode: 20,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/6ktqXnPIoXc",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/OSAyENQbSrU",
      },
    ],
  },
  {
    episode: 21,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/alCESTWTAuY",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/Nx0VwNt8WJ8",
      },
    ],
  },
  {
    episode: 22,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/yEoQYYYNTeg",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/ctK-rmzlmpo",
      },
    ],
  },
  {
    episode: 23,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/NOVbsgRNCCc",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/2jxos2OZask",
      },
    ],
  },
  {
    episode: 24,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/F6CNkkYwCMs",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/ztUlGYlbJYU",
      },
    ],
  },
  {
    episode: 25,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/qwa9ywA4oh8",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/_99x48p5ba8",
      },
    ],
  },
  {
    episode: 26,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/an6UO7I8ud0",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/O-gQjdmCDJs",
      },
    ],
  },
];

export default listeEpisodes;
