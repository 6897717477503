const listeEpisodes = [
    {
        episode: 1,
        link: "https://video.sibnet.ru/shell.php?videoid=4926363",
    },
    {
        episode: 2,
        link: "https://video.sibnet.ru/shell.php?videoid=4926366",
    },
    {
        episode: 3,
        link: "https://video.sibnet.ru/shell.php?videoid=4926368",
    },
    {
        episode: 4,
        link: "https://video.sibnet.ru/shell.php?videoid=4926369",
    },
    {
        episode: 5,
        link: "https://video.sibnet.ru/shell.php?videoid=4926371",
    },
    {
        episode: 6,
        link: "https://video.sibnet.ru/shell.php?videoid=4926373",
    },
    {
        episode: 7,
        link: "https://video.sibnet.ru/shell.php?videoid=4926377",
    },
    {
        episode: 8,
        link: "https://video.sibnet.ru/shell.php?videoid=4926382",
    },
    {
        episode: 9,
        link: "https://video.sibnet.ru/shell.php?videoid=4926387",
    },
    {
        episode: 10,
        link: "https://video.sibnet.ru/shell.php?videoid=4926389",
    },
    {
        episode: 11,
        link: "https://video.sibnet.ru/shell.php?videoid=4926393",
    },
    {
        episode: 12,
        link: "https://video.sibnet.ru/shell.php?videoid=4926362",
    },
    {
        episode: 13,
        link: "https://video.sibnet.ru/shell.php?videoid=4926397",
    },
    {
        episode: 14,
        link: "https://video.sibnet.ru/shell.php?videoid=4926402",
    },
    {
        episode: 15,
        link: "https://video.sibnet.ru/shell.php?videoid=4926421",
    },
    {
        episode: 16,
        link: "https://video.sibnet.ru/shell.php?videoid=4926429",
    },
    {
        episode: 17,
        link: "https://vkvideo.ru/video_ext.php?oid=646754736&id=456239029&hd=2",
        dllink: "https://drive.google.com/uc?export=download&id=1RUw78rDk69Od3BTIy4gf7yfbY-CzAw3l",
    },
    {
        episode: 18,
        link: "https://vkvideo.ru/video_ext.php?oid=646754736&id=456239028&hd=2",
        dllink: "https://drive.google.com/uc?export=download&id=1YzgeirtvH4y13DkRN8uShaAIVvf3xi6C",
    },
    {
        episode: 19,
        link: "https://vkvideo.ru/video_ext.php?oid=646754736&id=456239027&hd=2",
        dllink: "https://drive.google.com/uc?export=download&id=10i_-efIt0Qc0wCZXanQhO7cOfGAENYoJ",
    },
    {
        episode: 20,
        link: "https://vkvideo.ru/video_ext.php?oid=646754736&id=456239026&hd=2",
        dllink: "https://drive.google.com/uc?export=download&id=1ID9fWYkzGj8fTUzGckg4-YoPQY5RjWE-",
    },
    {
        episode: 21,
        link: "https://vkvideo.ru/video_ext.php?oid=646754736&id=456239025&hd=2",
        dllink: "https://drive.google.com/uc?export=download&id=1MSM4wLpUPf_h4w800doPmtXw-ngJsNV7",
    },
    {
        episode: 22,
        link: "https://vkvideo.ru/video_ext.php?oid=646754736&id=456239024&hd=2",
        dllink: "https://drive.google.com/uc?export=download&id=1dyFHuICrdfwLuJQeVydf-eJgZ6qIcm5a",
    },
    {
        episode: 23,
        link: "https://vkvideo.ru/video_ext.php?oid=646754736&id=456239023&hd=2",
        dllink: "https://drive.google.com/uc?export=download&id=1bGlNgtYLHHKYE4pNip5nJx1QRS7xdv-K",
    },
    {
        episode: 24,
        link: "https://vkvideo.ru/video_ext.php?oid=646754736&id=456239022&hd=2",
        dllink: "https://drive.google.com/uc?export=download&id=1hHr9IpRY5bSDRMjnHnHww7gMMAiU-JQx",
    },
];

export default listeEpisodes;
