const listeEpisodes = [
  {
    episode: 1,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/fSnCwPJA_Q8",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/HXd1xVL2JX0",
      },
    ],
  },
  {
    episode: 2,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/g69pKjdQ9o4",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/WOmz6O84SkA",
      },
    ],
  },
  {
    episode: 3,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Z35YMbNFaRA",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/ftpqbPdXpyE",
      },
    ],
  },
  {
    episode: 4,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/OHqF4lyvYE4",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/mYbMLACA7PE",
      },
    ],
  },
  {
    episode: 5,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/lavMfC6vZDk",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/io1AGHxduxg",
      },
    ],
  },
  {
    episode: 6,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Gb9v4vSH2vU",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/U9d8jky27Lk",
      },
    ],
  },
  {
    episode: 7,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Tkum4Z4HdA8",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/_khT-TjT5ig",
      },
    ],
  },
  {
    episode: 8,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/7Yc1OV7RUbo",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/ImBalVrf_wU",
      },
    ],
  },
  {
    episode: 9,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/FzSlk5NBRt0",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/t2rjyHdWuK4",
      },
    ],
  },
  {
    episode: 10,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Z84WBXh9DGI",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/pXvIZSsKzp0",
      },
    ],
  },
  {
    episode: 11,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/G8X5wJCy3RI",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/bzDqN7emi98",
      },
    ],
  },
  {
    episode: 12,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/CY3IQMjlQZU",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/PkGozFmJIJc",
      },
    ],
  },
  {
    episode: 13,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/nyRu_Dlhr7s",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/fr4jihtDU9A",
      },
    ],
  },
  {
    episode: 14,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/RLsITIH_QU0",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/tT-T9o3CpAs",
      },
    ],
  },
  {
    episode: 15,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/mucRVsClpSo",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/6cguA8WRGl4",
      },
    ],
  },
  {
    episode: 16,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/TefNuAl0XiE",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/zs-YsRnZpzk",
      },
    ],
  },
  {
    episode: 17,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/tVoUzh7rDCM",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/p6TSPyqSKAo",
      },
    ],
  },
  {
    episode: 18,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/9rusCrJ6GYM",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/B-FVW_dM3C0",
      },
    ],
  },
  {
    episode: 19,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/F8IPxvu2j5Q",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/t7aliNsmCpo",
      },
    ],
  },
  {
    episode: 20,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/u7D2UWT9ps8",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/Jmh67x9ON4k",
      },
    ],
  },
  {
    episode: 21,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/vpSQiycCKv4",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/p0ZVgKDnMJ0",
      },
    ],
  },
  {
    episode: 22,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/YkHpsYHdhj0",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/-Jhz2Xn4LBo",
      },
    ],
  },
  {
    episode: 23,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/oQriEYI3Xm8",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/ilxBOJZKSMI",
      },
    ],
  },
  {
    episode: 24,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/-YjRaFEHQUk",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/vwy7L3fP6yU",
      },
    ],
  },
  {
    episode: 25,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/tfrJ3eb0etU",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/psuayT1xkbU",
      },
    ],
  },
  {
    episode: 26,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/WE5dUk69KDM",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/UOVsHIckNY4",
      },
    ],
  },
  {
    episode: 27,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Pr01hTvZj2c",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/vQIzCRpR3SM",
      },
    ],
  },
  {
    episode: 28,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/57NiHuHzkyE",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/yHnpgfrG4G4",
      },
    ],
  },
  {
    episode: 29,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/fBX64QlhD_U",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/NSEWTss-MY8",
      },
    ],
  },
  {
    episode: 30,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/LEoyylqn1hU",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/tuXxz7SgO8o",
      },
    ],
  },
  {
    episode: 31,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/gaBtC5jV3Fs",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/spA18lflTbQ",
      },
    ],
  },
  {
    episode: 32,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/_ahqwPA-tBU",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/lBayn8H8weQ",
      },
    ],
  },
  {
    episode: 33,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/XzhGuIwY7CY",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/BBZieS8f_v4",
      },
    ],
  },
  {
    episode: 34,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/h43laN7YZhQ",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/92tdrt8KC0g",
      },
    ],
  },
  {
    episode: 35,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/AHlR5If2OpU",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/b6-V_NPs2gY",
      },
    ],
  },
  {
    episode: 36,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/TRQIxV-wvHY",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/TE7Ftkc-xus",
      },
    ],
  },
  {
    episode: 37,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/Y0IN8S7OKEs",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/7fcSSqyvtF8",
      },
    ],
  },
  {
    episode: 38,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/I6EOmG2qj-Y",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/pu_suGZOk8A",
      },
    ],
  },
  {
    episode: 39,
    multi: true,
    links: [
      {
        name: "YouTube HD",
        link: "https://www.youtube.com/embed/gS724C8dS4U",
      },
      {
        name: "YouTube LD",
        link: "https://www.youtube.com/embed/ctiDRAe2HLE",
      },
    ],
  },
];

export default listeEpisodes;
