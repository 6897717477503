import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ErrorPage from "../components/ErrorPage";

export default function FilmDisplay() {
  const [data, setData] = useState([]);
  const [film, setFilm] = useState([]);
  const [status, doesItWorked] = useState(false);
  const [filmLink, setFilmLink] = useState();
  const param = useParams();
  const generationURL = param.generation;
  const langageURL = param.langue;

  useEffect(() => {
    try {
      setData(require(`../datas/films/${generationURL}.js`).default);
      setFilm(trouverFilm(data, langageURL));
      doesItWorked(true);
    } catch (error) {}
  }, [doesItWorked, generationURL, langageURL, data]);

  function trouverFilm(films, langageURL) {
    for (let i = 0; i < films.length; i++) {
      if (films[i].language === langageURL) {
        if (films[i].multi) {
          setFilmLink(films[i].links[0].link);
        } else {
          setFilmLink(films[i].link);
        }
        return films[i];
      }
    }
    return "none";
  }

  function handleChange(event) {
    setFilmLink(event.target.value);
  }

  if (langageURL !== "vostfr" && langageURL !== "vf") {
    return <ErrorPage />;
  }

  let actualGeneration;

  if (generationURL === "bakuten") {
    actualGeneration = "Bakuten Shoot Beyblade";
  } else if (generationURL === "metal") {
    actualGeneration = "Metal Fight Beyblade";
  } else {
    return <ErrorPage />;
  }

  if (status === false) {
    return <ErrorPage />;
  }

  if (film === "none") {
    return <ErrorPage />;
  }

  return (
    <>
      <section className="film-section">
        <div className="title-container">
          <div className="title-decoration"></div>
          <h1>{actualGeneration.toUpperCase()} - LE FILM</h1>
        </div>
        <div className="title-container second">
          <div className="left-part">
            <div className="title-decoration"></div>
            <h1>
              {film.titre.toUpperCase()} EN {film.language.toUpperCase()}
            </h1>
          </div>
          {film.multi ? (
            <div className="right-part">
              <h2>LECTEUR :</h2>
              <div className="select-container">
                <select value={filmLink} onChange={handleChange}>
                  {film.links.map((link) => (
                    <option value={link.link} key={link.name}>
                      {link.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="film-iframe-section">
          <iframe
            src={filmLink}
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            title={film.titre}
          ></iframe>
          <a
            href="https://discordapp.com/invite/HZEpzZ3"
            target="_blank"
            rel="noreferrer"
            className="film-report"
          >
            SIGNALER UN PROBLÈME SUR LE FILM
          </a>
        </div>
      </section>
    </>
  );
}
